import { memo, useCallback, useState } from 'react';
import _isNil from 'lodash.isnil';
import { ButtonBase, DialogActions, DialogContent, DialogTitle, Rating } from '@mui/material';
import { cx } from '@emotion/css';

import ImgSrcSurveyCompleted from './assets/survey_filled.svg';
import ImgSrcSurveyToStart from './assets/survey_to_start.svg';
import ImgSrcRemindSurvey from './assets/survey.svg';

import cn from './surveyContent.module.scss';

import { QUESTION_RANGES, QUESTIONS_TYPES } from './constants';

import SimpleMd from '../SimpleMd';
import Confetti from '../Confetti';
import TextField from '../TextField';
import CancelButton from '../Buttons/CancelButton';
import StyledButton from '../Buttons/StyledButton';
import StyledLoadingButton from '../Buttons/StyledLoadingButton';

const SurveyContent = ({ i18n, ...props }) => {

    const [step, setStep] = useState(props.asAdmin ? 'survey' : 'introduction');

    const _onClickStart = useCallback(() => { setStep('survey'); }, []);
    const _onClickCancel = useCallback(() => setStep('remind'), []);
    const _onSurveySubmitted = useCallback(() => setStep('congratz'), []);

    if (step === 'introduction') { return <Introduction {...props} onStart={_onClickStart} onCancel={_onClickCancel} i18n={i18n.introduction} />; }
    if (step === 'survey') return <Survey {...props} onCancel={_onClickCancel} onSurveySubmitted={_onSurveySubmitted} i18n={i18n.content} />;
    if (step === 'remind') return <RemindLater i18n={i18n.remind} {...props} />;
    if (step === 'congratz') return <Congratz i18n={i18n.congrats} {...props} />;

    return null;
};

const Introduction = memo(({ onCancel, onStart, i18n, firstName }) => {

    return (<>
        <DialogContent>
            <div className={cn.title}>
                {i18n.title.replace('{{firstName}}', firstName)}
            </div>
            <SimpleMd value={i18n.body} />
            <div className={cn.illustration}>
                <ImgSrcSurveyToStart alt="" />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.cancel} onClick={onCancel} />
            <StyledButton label={i18n.go} onClick={onStart} />
        </DialogActions>
    </>);
});

const RemindLater = memo(({ onDiscardSurvey, survey, i18n, endDate }) => {

    const _onClick = useCallback(() => onDiscardSurvey(survey.id), [onDiscardSurvey, survey]);

    return (<>
        <DialogContent>
            <SimpleMd className={cn.remindContent} value={i18n.body.replace('{{date}}', endDate)} />
            <div className={cn.illustration}>
                <ImgSrcRemindSurvey alt="" />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.close} onClick={_onClick} />
        </DialogActions>
    </>);
});

const Congratz = memo(({ onDiscardSurvey, survey, i18n, firstName }) => {

    const _onClose = useCallback(() => onDiscardSurvey(survey.id), [onDiscardSurvey, survey.id]);

    return (<>
        <DialogContent>
            <div className={cn.title}>
                {i18n.title.replace('{{firstName}}', firstName)}
            </div>
            <SimpleMd value={i18n.body} />
            <div className={cn.illustration}>
                <ImgSrcSurveyCompleted alt="" />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.close} onClick={_onClose} />
        </DialogActions>
        <Confetti />
    </>);
});

const Survey = memo(({ survey, started, onAnswerQuestion, onSubmitSurvey, onSurveySubmitted, asAdmin, onCancel, onClose, i18n, isDarkMode, locale }) => {

    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const _onClickCancel = useCallback(() => {
        if (asAdmin) {
            onClose();
            return;
        }
        if (!started || window.confirm(i18n.confirmCancel)) onCancel();
    }, [asAdmin, started, i18n.confirmCancel, onCancel, onClose]);

    const _onChangeSection = useCallback(sectionIndex => setActiveSectionIndex(sectionIndex), []);
    // Theses 2 function do not need to be wrapped in useCallback as they would rely on "survey" i their dependency array, and survey changes everytime
    const _onCompleteSurvey = async () => {
        setLoading(true);
        await onSubmitSurvey(survey);
        setLoading(false);
        onSurveySubmitted();
    };

    const _onMoveToNextSection = () => setActiveSectionIndex(index => index + 1);

    const activeSection = survey.sections[activeSectionIndex];
    const isFinalSection = activeSectionIndex === survey.sections.length - 1;

    // We can go next if every mandatory questions are filled (or we are admin, we can always go next)
    const canGoNext = asAdmin || activeSection.questions.filter(q => q.required).every(q => {
        if (q.type === QUESTIONS_TYPES.CSAT.id)
            return q.value && q.value >= QUESTION_RANGES.CSAT.MIN && q.value <= QUESTION_RANGES.CSAT.MAX;
        if (q.type === QUESTIONS_TYPES.NPS.id)
            return !_isNil(q.value) && q.value >= QUESTION_RANGES.NPS.MIN && q.value <= QUESTION_RANGES.NPS.MAX;
        if (q.type === QUESTIONS_TYPES.RATING.id)
            return q.value && q.value >= QUESTION_RANGES.RATING.MIN && q.value <= QUESTION_RANGES.RATING.MAX;

        // TEXT
        return q.value && q.value.trim() !== '';
    });

    return (<>
        <DialogTitle className={cn.breadcrumb}>
            <Breadcrumb current={activeSectionIndex} total={survey.sections.length} asAdmin={asAdmin} onClick={_onChangeSection} isDarkMode={isDarkMode} />
        </DialogTitle>
        <DialogContent className={cn.surveyContainer}>
            <Section content={activeSection} onAnswerQuestion={onAnswerQuestion} disabled={loading || asAdmin} i18n={i18n.csat} isDarkMode={isDarkMode} locale={locale} />
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.cancel} onClick={_onClickCancel} disabled={loading} />
            <StyledLoadingButton
                label={isFinalSection ? i18n.submit : i18n.next}
                onClick={isFinalSection ? _onCompleteSurvey : _onMoveToNextSection}
                disabled={!canGoNext || (isFinalSection && asAdmin)}
                loading={loading}
            />
        </DialogActions>
    </>);

});

const Breadcrumb = memo(({ onClick, current, total, asAdmin, isDarkMode }) => {

    // No need to display a breadcrumb with only one section
    if (total <= 1) return null;

    return (
        <div className={cn.breadContainer}>
            {
                Array.from({ length: total }).map((_, i) => {
                    const isClickable = (asAdmin || (i < current)) && current !== i;

                    return (
                        <span
                            key={i}
                            className={cx(
                                cn.breadSection,
                                isDarkMode && 'dark',
                                isClickable && 'clickable',
                                i < current && 'past',
                                i === current && 'current'
                            )}
                            onClick={() => isClickable && onClick(i)}
                        />
                    );
                })
            }
        </div>
    );

});

const Section = memo(({ content, onAnswerQuestion, disabled, isDarkMode, locale, ...props }) => {

    const description = content.description[locale];

    return (<>
        <div className={cx(cn.sectionContainer, cn.sectionTitleContainer, isDarkMode && 'dark')}>
            <div className={cn.sectionTitle}>
                {content.title[locale]}
            </div>
            {description && (
                <div className={cn.sectionDescription}>
                    {description}
                </div>
            )}
        </div>
        <div className={cn.sectionContainer}>
            {content.questions.map((q, i, a) => (
                <QuestionDispatcher
                    key={q.id}
                    {...q}
                    onAnswerQuestion={onAnswerQuestion}
                    isSameTypeAsAbove={i > 0 && q.type === a[i - 1].type}
                    disabled={disabled}
                    isDarkMode={isDarkMode}
                    locale={locale}
                    {...props}
                />
            ))}
        </div>
    </>);

});

const QuestionDispatcher = memo(({ id, type, onAnswerQuestion, ...data }) => {

    const _onChange = useCallback(value => onAnswerQuestion({ id, value }), [onAnswerQuestion, id]);

    if (type === QUESTIONS_TYPES.RATING.id)
        return <QuestionStarRating {...data} onChange={_onChange} />;
    if (type === QUESTIONS_TYPES.CSAT.id)
        return <QuestionCircleRating
            {...data}
            onChange={_onChange}
            minValue={QUESTION_RANGES.CSAT.MIN}
            maxValue={QUESTION_RANGES.CSAT.MAX}
        />;
    if (type === QUESTIONS_TYPES.NPS.id)
        return <QuestionCircleRating
            {...data}
            onChange={_onChange}
            minValue={QUESTION_RANGES.NPS.MIN}
            maxValue={QUESTION_RANGES.NPS.MAX}
        />;

    // Text
    return <QuestionText {...data} onChange={_onChange} />;

});

const QuestionStarRating = memo(({ content, required, value, isSameTypeAsAbove, onChange, disabled, i18n, isDarkMode, locale }) => {

    const _onChangeRating = useCallback((_, value) => onChange(value), [onChange]);

    return (<>
        {!isSameTypeAsAbove && (
            <div className={cx(cn.starRatingContainer, isDarkMode && 'dark', cn.explanations)}>
                <div className={cn.starQuestion} />
                <div className={cx(cn.rating, cn.ratingExplanations)}>
                    <span className={cn.cellExplanations}>
                        {i18n.disagree}
                    </span>
                    <span className={cn.grow} />
                    <span className={cx(cn.cellExplanations, 'right')}>
                        {i18n.agree}
                    </span>
                </div>
            </div>
        )}
        <div className={cx(cn.starRatingContainer, isDarkMode && 'dark')}>
            <div className={cx(cn.starQuestion, required && 'required')}>
                {content[locale]}
            </div>
            <div className={cn.rating}>
                <Rating
                    value={value ?? 0}
                    onChange={_onChangeRating}
                    size="large"
                    required
                    precision={0.5}
                    disabled={disabled}
                />
            </div>
        </div>
    </>);
});

const QuestionText = memo(({ content, required, value, onChange, disabled, locale }) => {

    const _onChange = useCallback(({ value }) => onChange(value), [onChange]);

    return (
        <div className={cn.textQuestionContainer}>
            <TextField
                label={content[locale]}
                required={required}
                value={value ?? ''}
                onChange={_onChange}
                multiline
                rows={5}
                fullWidth
                disabled={disabled}
            />
        </div>
    );

});

const QuestionCircleRating = memo(({ content, required, value, onChange, disabled, minValue, maxValue, isDarkMode, locale }) => {

    const _onChange = useCallback(value => onChange(value), [onChange]);

    const length = (maxValue - minValue) + 1;

    return (
        <div className={cx(cn.circleRatingContainer, isDarkMode && 'dark')}>
            <div className={cx(cn.circleRatingQuestion, required && 'required')}>
                {content[locale]}
            </div>
            <div className={cn.circleRating}>
                {Array.from({ length }).map((_, i) => (
                    <CircleItem key={i} value={i + minValue} active={i + minValue === value} onClick={_onChange} disabled={disabled} />
                ))}
            </div>
        </div>
    );

});

const CircleItem = memo(({ value, active, onClick, disabled }) => {

    const _onClick = useCallback(() => onClick(value), [onClick, value]);

    return (
        <ButtonBase
            onClick={_onClick}
            className={cx(cn.item, active && 'active')}
            focusRipple
            disabled={disabled}
        >
            {value}
        </ButtonBase>
    );

});

export default memo(SurveyContent);

