export const QUESTIONS_TYPES = {
    CSAT: {
        id: 'csat',
        color: 'blue'
    },
    NPS: {
        id: 'nps',
        color: 'red'
    },
    PODIUM: {
        id: 'user',
        color: 'orange'
    },
    RATING: {
        id: 'rating',
        color: 'green'
    },
    TEXT: {
        id: 'text',
        color: 'purple'
    }
};

export const QUESTION_RANGES = {
    CSAT: {
        MIN: 1,
        MAX: 5
    },
    NPS: {
        MIN: 0,
        MAX: 10
    },
    RATING: {
        MIN: 1,
        MAX: 10
    }
};
