import { memo } from 'react';
import { Typography } from '@mui/material';
import { cx } from '@emotion/css';

import { Chip, Heading, ProgressBar } from '_ui';
import Logo from '../assets/logo_WCS_black_bottom_left.svg';

import { useI18n } from '../global/hooks/i18n';
import { useLocale } from '../global/hooks/locale';

import Button from './Button';

import cn from './card.module.scss';

type CardProps = {
    ctaLabel: string;
    goToTraining: () => void | Promise<void>;
    isAvailable: boolean;
    loading: boolean;
    name: string;
    trainingType: string;
    track: { [key: string]: string };
    headTag?: React.ReactNode;
    estimatedTime?: number;
    startsOn?: Date;
    progression?: number;
}

const Card = ({ ctaLabel, goToTraining, isAvailable, loading, name, trainingType, track, startsOn, progression }: CardProps) => {

    const t = useI18n('card');
    const locale = useLocale();

    return <article className={cx(cn.card, `training--${track.color}`)}>
        <div className={cn.content}>
            <div className={cn.headTag}>
                <Logo />
            </div>
            <div className={cn.track}>
                <Typography className={cn.track}>{track.name}</Typography>
            </div>
            <div className={cn.typenTime}>
                <Typography className={cn.type}>{t(`trainingTypes.${trainingType}`)}</Typography>
                {startsOn && <Chip content={t('startsOn', { date: new Date(startsOn).toLocaleDateString(locale) })} />}
            </div>
            <div className={cn.training}>
                <Heading heading="h4" className={cx(cn.name, startsOn && cn.clampThree)}>{name}</Heading>
                {Number.isInteger(progression) && <ProgressBar progress={progression} className={cn.progress} />}
            </div>
            <div className={cn.cta}>
                <Button disabled={!isAvailable}
                    onClick={goToTraining}
                    label=""
                    className={cn.cardButton}
                    loading={loading}
                    aria-label={t(`startTraining.${ctaLabel}`)}
                />
            </div>
        </div>
    </article >;
};

export default memo(Card);
