import { useCallback, useMemo } from 'react';
import { startOfToday } from 'date-fns';

import { getTranslatable } from '_i18n';

import type { AsyncEnrollment } from '../../enrollments/types';
import { useLocale } from '../../global/hooks/locale';
import { getEnv } from '../../global/config/env';

export const useAsyncCardInfos = (enrollment: AsyncEnrollment) => {
    const { asyncUrl } = getEnv();

    const locale = useLocale();

    const ctaLabel = useMemo(() => {
        // cta labels are different between discovery and skill course
        if (enrollment.isCompleted) return `${enrollment.trainingType}.restart`;
        if (enrollment.isStarted) return `${enrollment.trainingType}.continue`;
        return `${enrollment.trainingType}.start`;
    }, [enrollment]);

    const goToTraining = useCallback(() => {
        // must redirect to the selected training
        window.location.href = `${asyncUrl}/trainings/${enrollment.programId}`;
    }, [asyncUrl, enrollment]);

    // async training are not available before the starts_on date
    const isAvailable = useMemo(() => enrollment.startsOn <= startOfToday(), [enrollment]);
    const track = enrollment.track;
    return {
        ctaLabel,
        goToTraining,
        isAvailable,
        name: enrollment.name,
        loading: false,
        track: {
            color: track.color,
            name: getTranslatable(track.name_translations, locale)
        },
        trainingType: enrollment.trainingType
    };
};
