import { memo } from 'react';
import { Skeleton } from '@mui/material';
import { cx } from '@emotion/css';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import '_ui/fonts';
import { Heading, SystemAlert } from '_ui';

import { useI18n } from './global/hooks/i18n';
import { useSignedInUser } from './global/hooks/user';
import { useEnrollments } from './enrollments/hook';
import { usePageTracker } from './tracking/hooks';

import { getEnv } from './global/config/env';
import routes from './global/config/routes';
import { useLocale } from './global/hooks/locale';

import AppBarWrapper from './appBar/AppBarWrapper';
import CardWrapper from './card/CardWrapper';
import MasqueradeHeadband from './masqueradeHeadband/MasqueradeHeadband';
import ConfirmationBottomBanner from './confirmationBottomBanner/ConfirmationBottomBanner';
import Certificates from './certificates/Certificates';

import Footer from './footer/Footer';
import Legals from './legals/Legals';

import '_ui/scss/trainingsCategories.scss';
import cn from '_ui/scss/app.module.scss';
import ds from './app/app.module.scss';

import type { User } from './profile/types';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

function App() {
    const { user } = useSignedInUser();
    const locale = useLocale();

    const { certificates, legals, universe } = getEnv();

    usePageTracker();

    return (
        <div className={cx(cn.app, `universe--${universe}`)}>
            <SystemAlert locale={locale} theme="light" />
            <AppBarWrapper />
            <main className={ds.container}>
                <SentryRoutes>
                    <Route path="/" element={<Home user={user} />} />
                    {certificates?.active && <Route path="/certificates" element={<Certificates />} />}
                    {legals?.items?.map(item => (
                        <Route
                            key={item}
                            path={routes[item as keyof typeof routes]}
                            element={<Legals id={item} />}
                        />
                    ))}
                    <Route path="*" element={<Navigate to="/" replace />} />
                </SentryRoutes>
            </main>
            {user && !user.isValidated && <ConfirmationBottomBanner user={user} />}
            {user?.isMasquerading && <MasqueradeHeadband user={user} />}
            <Footer />
        </div>
    );
}

type HomeProps = {
    user?: User;
}
const Home = memo(({ user }: HomeProps) => {
    const t = useI18n('app');

    const { isLoading, enrollments } = useEnrollments();

    return (
        <>
            <Heading heading="h2" className={ds.welcome}>{t('welcome', { firstname: user?.firstname })}</Heading>
            <Heading heading="h3" className={ds.title}>{t('title')}</Heading>
            <div className={ds.cards}>
                {isLoading && (
                    <Skeleton className={ds.skeleton} variant="rectangular" width="100%" height={328} />
                )}
                {enrollments && enrollments.map(enrollment => <CardWrapper key={`${enrollment.type}-${enrollment.id}`} enrollment={enrollment} />)}
            </div>
        </>
    );
});

export default memo(App);
