import { memo } from 'react';
import { ButtonBase, CircularProgress } from '@mui/material';
import { ChevronRightRounded as RightIcon } from '@mui/icons-material';
import { cx } from '@emotion/css';

import nav from '_ui/scss/navigation.module.scss';

type ButtonProps = {
    disabled: boolean;
    label: string;
    onClick: () => void | Promise<void>;
    className?: string;
    loading: boolean;
}

const Button = ({ disabled, label, onClick, className, loading, ...props }: ButtonProps) => {
    const isDisabled = loading || disabled;
    return (
        <ButtonBase className={cx(nav.button, nav.next, className, isDisabled && nav.disabled)} onClick={onClick} disabled={isDisabled} {...props} >
            <span>{label}</span>
            {loading ? <CircularProgress size={20} color="primary" className={cx(nav.loader)} /> : <RightIcon />}
        </ButtonBase>
    );
};

export default memo(Button);
